// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quotes-js": () => import("./../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-relationship-space-js": () => import("./../src/pages/relationship-space.js" /* webpackChunkName: "component---src-pages-relationship-space-js" */)
}

